angular.module( 'bystro', [
  'templates-app',
  'templates-common',
  'ngResource',
  'ngSanitize',
  'ngRoute',
  'ngAnimate',
  'ngAria',
  'ngMessages', 
  'ngMaterial',

  /* other portions of the site*/
  'sq.serverEvents.service',
  'sq.appController',
  'sq.config',
  'sq.home',
  'sq.newhome',
  'sq.about',
  'sq.jobs',
  'sq.navbar.component',
  'sq.user',
  'sq.help',
  'sq.common.loading.component',
  // 'sq.common.welcome.component',
  'sq.services.socketIO',

  // 3rd party
  'btford.markdown',
])
.run(function runFn(socketIO, Auth, $http, SETTINGS,authEvents, serverEvents, jobTracker, userProfile,
jobSubmit, $timeout, $log, $window, $q, jobEvents, uploadToS3, Uploader, $document, $rootScope, $location, $animate, AUTH_INTERCEPTOR) {
  jobEvents.listen();
  authEvents.listen();
  userProfile.listen();
  jobSubmit.listen();
  uploadToS3.listen();
  Uploader.listen();

  userProfile.initializeAsync().then(() => {
    socketIO.connect();
    return jobTracker.initializeAsync();
  }).catch((rejection) => {
    $log.debug('login or job tracker initialization rejected', rejection);
  });

  // TODO: delay route resolve(s) until Auth.verifyLoggedInAsync() returns
  // TODO: move into separate component
  $rootScope.$on('$routeChangeStart', function(event, next, current) {
    if (next.secureAsync) {
      return Auth.verifyLoggedInAsync().catch((error) => {
        event.preventDefault();

        const searchPath = {
          message: 'Log in for the ' + next.title + ' page',
          path: next.originalPath,
        };

        const superParams = Object.assign({}, next.pathParams, next.params);
        if(Object.keys(superParams).length) {
          Object.keys(superParams).forEach( (paramName) => {
            searchPath.path = searchPath.path.replace(`:${paramName}?`, '');
            searchPath.path = searchPath.path.replace(`:${paramName}`, '');
          });

          searchPath.params = superParams;
        } else {
          // No params found, so the parameter variables in the path should be stripped
          searchPath.path = searchPath.path.replace(/\:\S+/g, '');
        }

        $rootScope.$evalAsync(() => $location.path('/login').search(searchPath));
      });
    }
  });

  $rootScope.$on(`${AUTH_INTERCEPTOR.eventPrefix}:redirect:login`, (ev, data, nextPath) => {
    // console.info('received login redirect', data, nextPath);
      return Auth.verifyLoggedInAsync().catch((error) => {
        // If we just pass query/get variables as part of path, if the receiver
        // tries to $location.search(path) it will fail; Angular $location.path
        // doesn't support this.
        const parts = nextPath.split('?');

        const searchPath = {
          message: 'It looks like you\'re logged in a 2nd session. To protect your security, please log in again',
          path: parts[0],
          params: parts[1],
        };

        $rootScope.$evalAsync(() => $location.path('/login').search(searchPath));
      });
  });
});